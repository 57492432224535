import request from '@/util/request'

export function getShopInfo(params) {
  return request({
    url: '/Shop.asmx/GetShopInfo',
    method: 'GET',
    params,
  })
}

export function addShop(params) {
  return request({
    url: '/Shop.asmx/AddShop',
    method: 'get',
    params,
  })
}

export function updateShop(params) {
  return request({
    url: '/Shop.asmx/UpdateShop',
    method: 'get',
    params,
  })
}

export function bindShop(params) {
  return request({
    url: '/Shop.asmx/BindShop',
    method: 'get',
    params,
  })
}
export function unBindShop(params) {
  return request({
    url: '/Shop.asmx/UnBindShop',
    method: 'get',
    params,
  })
}

export function getBindShop(params) {
  return request({
    url: '/Shop.asmx/GetBindShop',
    method: 'get',
    params,
  })
}

export function checkMainShop(params) {
  return request({
    url: '/Shop.asmx/CheckMainShop',
    method: 'get',
    params,
  })
}

export function getShopList(params) {
  return request({
    url: '/Shop.asmx/GetShopList',
    method: 'get',
    params,
  })
}

export function shopColoration(params) {
  return request({
    url: '/Shop.asmx/ShopColoration',
    method: 'get',
    params,
  })
}

export function getShopColorationInfo(params) {
  return request({
    url: '/Shop.asmx/GetShopColorationInfo',
    method: 'get',
    params,
  })
}
