import request from '@/util/request'

export function ddLogin(params) {
  return request({
    url: '/Base.asmx/DDLogin',
    method: 'GET',
    params,
  })
}

export function getShopType(params) {
  return request({
    url: '/Base.asmx/GetShopTypeOptions',
    method: 'GET',
    params,
  })
}

export function getTeam(params) {
  return request({
    url: '/Base.asmx/GetTeamOptions',
    method: 'GET',
    params,
  })
}

export function getTeamCell(params) {
  return request({
    url: '/Base.asmx/GetTeamCellOptions',
    method: 'GET',
    params,
  })
}

export function getCellPerson(params) {
  return request({
    url: '/Base.asmx/GetCellPersonOptions',
    method: 'GET',
    params,
  })
}

export function uploadFile(data) {
  return request({
    url: '/Base.asmx/UploadFile',
    method: 'POST',
    data,
  })
}

export function getTeamInfo(params) {
  return request({
    url: '/Base.asmx/GetTeamInfo',
    method: 'get',
    params,
  })
}

export function getShopInfo(params) {
  return request({
    url: '/Base.asmx/GetShopInfo',
    method: 'get',
    params,
  })
}

export function getOptions(params) {
  return request({
    url: '/Base.asmx/GetOptions',
    method: 'get',
    params,
  })
}

export function getWithPerson(params) {
  return request({
    url: '/Base.asmx/GetWithPersonOptions',
    method: 'GET',
    params,
  })
}

export function Ls(params) {
  return request({
    url: '/Demo.asmx/LS',
    method: 'GET',
    params,
  })
}
