<template>
  <div style="background: white">
    <van-cell-group>
      <van-cell title="扫码结果" :label="streetName" size="large" />
      <van-cell title="店铺信息" :label="shopName" size="large" />
    </van-cell-group>
    <van-row v-if="shopID === 0">
      <van-col :span="24" style="text-align: center">
        <van-button type="info" icon="guide-o" @click="handleShop(1)">
          绑定
        </van-button>
      </van-col>
    </van-row>
    <van-row v-else>
      <van-col :span="12" style="text-align: right;padding: 5px">
        <van-button type="danger" icon="cross" @click="handleShop(3)">
          解绑
        </van-button>
      </van-col>
      <van-col :span="12" style="text-align: left;padding: 5px">
        <van-button type="warning" icon="plus" @click="handleShop(2)">
          修改
        </van-button>
      </van-col>
    </van-row>
    <van-row style="margin-top: 20px">
      <van-grid v-show="shopID != 0" :column-num="4">
        <van-grid-item
          v-for="(item, index) in list"
          v-show="item.isShow"
          :key="index"
          :text="item.text"
          :icon="item.photo"
          @click="openWin(item)"
        />
      </van-grid>
    </van-row>
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { Dialog, Toast } from 'vant'
  import { getShopInfo } from '@/api/base/base'
  import { checkMainShop } from '@/api/workBench/shop/shop'
  import { checkRole } from '@/util/validate'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'Index',
    data() {
      return {
        street_ID: '',
        shopID: 0,
        shopName: '',
        streetName: '',
        isShow: false,
        list: [
          {
            text: '劝导',
            icon: 'note',
            photo: require('@/assets/images/outDoor/scan/advise.png'),
            path: '/adviseAdd',
            isShow: true,
          },
          {
            text: '劝导记录',
            icon: 'note',
            photo: require('@/assets/images/outDoor/scan/advise.png'),
            path: '/advise',
            isShow: true,
          },
          {
            text: '店铺走访',
            icon: 'shopView',
            photo: require('@/assets/images/outDoor/scan/shopView.png'),
            path: '/addView',
            isShow: true,
          },
          {
            text: '走访记录',
            icon: 'zouFang',
            photo: require('@/assets/images/outDoor/scan/zoufang.png'),
            path: '/shopView',
            isShow: true,
          },
          {
            text: '办案',
            icon: 'case',
            photo: require('@/assets/images/outDoor/scan/case.png'),
            path: '/caseAdd',
            isShow: true,
          },
          {
            text: '办案记录',
            icon: 'caseJL',
            photo: require('@/assets/images/outDoor/scan/zaibananjian.png'),
            path: '/case',
            isShow: true,
          },
          {
            text: '店铺评分',
            icon: 'shopGrade',
            photo: require('@/assets/images/outDoor/scan/shopGrade.png'),
            path: '/shopGradeAdd',
            isShow: true,
          },
          {
            text: '评分记录',
            icon: 'shopGradeJL',
            photo: require('@/assets/images/outDoor/scan/shopGradeJL.png'),
            path: '/shopGrade',
            isShow: true,
          },
          {
            text: '重点店铺设置',
            icon: 'qr',
            photo: require('@/assets/images/outDoor/scan/qr.png'),
            path: '/shopQR',
            isShow: this.isShow,
          },
          {
            text: '夜间中队设置',
            icon: 'qr',
            photo: require('@/assets/images/outDoor/scan/qr.png'),
            path: '/teamQR',
            isShow: this.isShow,
          },
        ],
      }
    },

    beforeCreate() {
      this.isShow = checkRole(3)
    },
    created() {
      this.street_ID = this.$route.query.street_ID
      this.fetchShopInfo()
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(staffName, userId, 'scan', '城管-店铺绑定', 'zzdcg.yy.gov.cn/scan')
    },
    destroyed() {},
    methods: {
      async fetchShopInfo() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { success, data, msg } = await getShopInfo({
          Street_ID: this.street_ID,
        })
        Toast.clear()
        if (success) {
          this.streetName = data.Street_Name
          this.shopName =
            data.Shop_Name == '' || data.Shop_Name == null
              ? '当前地址未绑定任何店铺'
              : data.Shop_Name
          this.shopID = data.Shop_ID
        } else {
          Dialog.alert({
            title: '提示',
            message: msg,
          }).then(() => {
            this.$router.push('/index')
          })
        }
      },

      async handleShop(type) {
        if (type == 3) {
          let flag = await this.checkMainShop()
          if (!flag) {
            this.openBind(type)
          }
        } else {
          this.openBind(type)
        }
      },

      async checkMainShop() {
        const { data } = await checkMainShop({ Street_ID: this.street_ID })
        if (data) {
          Dialog.confirm({
            title: '提示',
            message: '请先进行取消重点店铺或夜间店铺操作，再进行店铺解绑。',
          }).then(() => {})
        }

        return data
      },

      openBind(type) {
        this.$router.push({
          path: '/bindShop',
          query: {
            street_ID: this.street_ID,
            streetName: Base64.encode(this.streetName),
            type: Base64.encode(type),
            shopID: Base64.encode(this.shopID),
          },
        })
      },

      //底部打开对应页面
      openWin(item) {
        let path = item.path
        this.$router.push({
          path: path,
          query: {
            shopID: Base64.encode(this.shopID),
            shopName: Base64.encode(this.shopName),
            street_ID: this.street_ID,
          },
        })
      },
    },
  }
</script>

<style scoped></style>
