import {appID, appName} from '@/config'

export function Emas(_user_nick, _user_id, page_id, page_name, page_url) {
    (function(w, d, s, q, i) {
        w[q] = w[q] || [];
        var f = d.getElementsByTagName(s)[0],j = d.createElement(s);
        j.async = true;
        j.id = 'beacon-aplus';
        j.src = 'https://alidt.alicdn.com/alilog/mlog/aplus_cloud.js';
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'aplus_queue');

    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['aplus-rhost-v', 'alog-api.ding.zj.gov.cn']
    });
    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['aplus-rhost-g', 'alog-api.ding.zj.gov.cn']
    });

    var u = navigator.userAgent
    var isAndroid = u.indexOf('Android') > -1
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['appId', isAndroid ? '28302650' : isIOS ? '28328447' : '47130293']
    });

    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['aplus-waiting', 'MAN']
    });
    //用户信息埋点
    // 如采集用户信息是异步行为需要先执行这个BLOCK埋点
    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['_hold', 'BLOCK']
    });

    //基础埋点
    // 单页应用 或 “单个页面”需异步补充PV日志参数还需进行如下埋点：
    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['aplus-waiting', 'MAN']
    });

    // 单页应用路由切换后 或 在异步获取到pv日志所需的参数后再执行sendPV：
    aplus_queue.push({
        'action':'aplus.sendPV',
        'arguments':[{
            is_auto: false
        }, {
            // 当前你的应用信息，此两行按应用实际参数修改，不可自定义。
            sapp_id: appID,
            sapp_name: appName,
            page_id: page_id,
            page_name: page_name,
            page_url:page_url,
            x: 111,
            y: 222
        }]
    })
    // 设置会员昵称
    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_user_nick", _user_nick],
    });

    // 设置会员ID
    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_user_id", _user_id]
    });
    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_dev_id", "设备ID是业务定义的，用于定义唯一的设备标识。这个目前没有要求，可不设置。"]
    });

    // 如采集用户信息是异步行为，需要先设置完用户信息后再执行这个START埋点
    // 此时被block住的日志会携带上用户信息逐条发出
    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['_hold', 'START']
    });

}

